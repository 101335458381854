// GETリクエストでjavascriptファイルを読み込みコールバック関数で実行
//$.getScript("../js/jquery.jcimodal.js"); //modalのjsを読み込み

// GTM(GoogleTagManager)のカスタムイベント記録用
const gtmDL = (event, eventCategory, eventAction, eventLabel, eventValue = 1) => {
  // eventValue以外の引数どれかがundefだったら早期リターン
  const isUndefAny = [event, eventCategory, eventAction, eventLabel].some(v => v === undefined);
  if (isUndefAny) return false;
  //eventValue = (typeof eventValue === 'undefined') ? 1: eventValue;
  dataLayer.push({ event, eventCategory, eventAction, eventLabel, eventValue, });
};

//スマホ幅だったらtrue返却 20200615TM
const return_isSP = () => ($(window).width() < 768);
let isSP = return_isSP(); // ブラウザサイズ変更時に再セットするためlet

//iOS判定　iOSだったらtrue返却 20180620TM
const isiOS = () => {
  const ua = window.navigator.userAgent;
  const iOS_flag = /iPhone|iPad|iPod/.test(ua);
  const iPad_flag = ua.indexOf('iPad') > -1 || ua.indexOf('Macintosh') > -1 && 'ontouchend' in document; //2019年9月のiPadOS13から判定を加える
  return iOS_flag || iPad_flag;
};

//音声オプション マニュアル用 →を見つけてアイコンspanタグに置換
const replace_txtArrow = () => {
  $(".icons:contains('→') ").each((i, elem) => elem.innerHTML = elem.innerHTML.replace(/→/g,'<span class="font_arrow"></span>'));
};

// 数値にカンマを付ける
const putComma = (num) => {
  return num.replace(/(\d)(?=(\d\d\d)+$)/g, '$1,');
};

// 外部スクリプト(URL)を閉じBODYタグの上の行に追加
const appendScript = (URL) => {
  const el = document.createElement('script');
  el.src = URL;
  document.body.appendChild(el);
};

// カレントURLをアクティブに&アクティブのタブまで自動スライド
const currentAutoSlide = () => {
  if ($(".naviContent li a").length != 0) {
    const url = window.location.href.split('/').pop();
    url = (url == "") ? "index.html" : url;
    url = (url.indexOf('s_') === 0) ? "support.html" : url;
    const current_tab = $('.naviContent li a[href*= "' + url + '"]');

    if (current_tab.length === 0) {
      $(".naviContent li:first-child a").addClass("product_current");
    } else {
      current_tab.addClass("product_current");
    }

    const slidable_navi = $('div.naviContent');//スライドするオブジェクト
    const slide_length = $(".naviContent li a[class*='product_current']").offset();//スライドする長さはcurrentタブの左まで
    if (slide_length !== undefined) {
      slidable_navi.animate({scrollLeft: slide_length.left});
    }
  }
};

//引数の文字列含むならtrue返却 20180710TM
//const searchStr = (searchstr, targetstr) => {
//  const searchRegEx = new RegExp(searchstr);
//  return (searchRegEx.test(targetstr));
//}

//要素存在判定　 20180711TM
const elemExist = (selector) => {
  const ex = (selector.includes('#')) ? document.getElementById(selector.substr(1)) : document.querySelector(selector);
  return (ex !== null);
};

const URLdiv = (url = window.location.href) => { // https://qiita.com/kerupani129/items/adc0fba4ab248330e443
  const matchedFileName = url.match(/^(?:[^:\/?#]+:)?(?:\/\/[^\/?#]*)?(?:([^?#]*\/)([^\/?#]*))?(\?[^#]*)?(?:#.*)?$/) ?? [];
  const urlArray = matchedFileName.map(match => match ?? '');
  const [, dir, filename, query] = urlArray;
  return {
    dir,
    filename,
    query,
  };
};

//URLディレクトリ部分を取得
const getUrlDir = () => {
  // / → ''
  // /path → 'path'
  // /path/ → 'path'
  // /path/path2 → 'path2'
  // /path/path2/ → 'path2'
  // /path/path2.html → 'path'
  // /path/path2.php → 'path'

  // URLインターフェースはbabel効かない
  //const url = new URL(window.location.href);
  //const pathnames = url.pathname.split('/');
  //const dir = (pathnames.slice(-1)[0].includes('.')) ? pathnames[pathnames.length -2] : pathnames.slice(-1)[0];
  const dir = URLdiv().dir.match(/^\/(.+)\/$/);
  return dir[1];
};

//ファイル名を取得
const getHtmlName = () => URLdiv().filename;

//URLパラメータ部分を取得
const getUrlParam = () => {
  return (location.search.length) ? location.search + '&' : '?';
};

//URLパラメータを取得しobjに格納
const UrlParamtoArray = () => {
  const paramObj = {};
  const paramArray = [...new URLSearchParams(location.search).entries()]; //→配列は得られるがオブジェクトは直接得られない
  paramArray.forEach(([k, v]) => {
    paramObj[k] = v; // オブジェクトにプロパティ追加
  });
  return paramObj;
};

//パンくずリスト生成(簡易)
const genSimpleBreadcrumbs = (sim) => {
  const simItem = ['pad', 'kaimaku', 'sumahod'];
  const simItemHref = {
    pad: ['190PadSIM TOP', '../190sim/index.html'],
    kaimaku: ['開幕SIM TOP', 'plan_post_kaimaku.html'],
    sumahod: ['スマホ電話SIM TOP', 'plan_post_kaimaku.html']
  };
  const currentName = window.document.title.split('|').shift();
  const breadlist = {
    pad: ['b-mobile', currentName]
  };
  let ulTag = '';
  let liInner = currentName;
  $.each(simItem, (key, val) => {
    for (let i = 0; i < 3; i++) {
      if (i === 0) {
        liInner = '<a href="../index.html">b-mobile</a>';
      }
      if (i === 1) {
        liInner = '<a href="' + simItemHref[val][1] + '">' + simItemHref[val][0] + '</a>';
      }
      //if (i === 2) {
      //  liInner = currentName;
      //}
      ulTag += '<li>' + liInner + '</li>';
    }
    $('.js-bread_crumbs.js-item_' + val).html('<ol>' + ulTag + '</ol>');
  });
}

//指定場所までスクロール 引数は$オブジェクト指定
const onClickScrollPosition = ($selectorObj) => {
  const pos = $selectorObj.offset().top;
  const scrollTo = pos - ($('.globalnav-wrap').outerHeight(true) * 2);
  const speed = (pos / 10) + 200;
  $('html,body').animate({scrollTop: scrollTo}, speed, 'swing');
};

// クリックされたタブに切り替え
// クリックするタブ部分ulに class="js-tab_area"をつける
// 切り替えるコンテンツ部分に  class="js-tabcontent_area"をつける
// クリックされた順番のところが連動して切り替わる
// indexはクリックされたタブliのインデックス
const tabSwitch = (e) => {
  const onloadContent = tabSwitchOnload || 'disp'; // tabSwitchOnload(呼出元で定義)未定義なら初期値'disp'
  const $content = $('.js-tabcontent_area');
  const $clickTab = (e.type === 'click') ? $(e.target).closest('li') : $('ul.js-tab_area li').eq(0); //e.targetはonでイベントが発生したオブジェクト。
  const index = (e.type === 'click') ? $clickTab.index() : 0; //クリックされたliのインデックス.load時は0
  const $targetContent = $content.eq(index); //クリックされたタブと同じ順番のコンテンツ

  $content.hide(); //コンテンツを一度すべて非表示
  if (e.type === 'load' && onloadContent === 'hide') { //load時呼出元で'hide'なら全て非表示にして早期リターン
    return false;
  }

  $clickTab.siblings().find('a').removeClass('active'); //タブについているクラスactiveを一度すべて消す

  if (e.type === 'click' && $(".border_b_blue").length) {
    const $tab_border = $(".border_b_blue");
    const border_color = (e.target.className.includes('marketbuy')) ? "#b9e6dc" : "#bbdefb"; //targetにmarketbuyのclassがあるか
    $tab_border.css("border-color", border_color);
  }
  $clickTab.find('a').addClass('active'); //クリックされたタブのみにクラスactiveをつける
  $targetContent.fadeIn("fast"); //クリックされたタブと同じ順番のコンテンツを表示
  onClickScrollPosition($targetContent); //コンテンツへスクロール

  setFlickity(index);
}

//classにsw-flickityがあれば、スマホ表示でflickityエレメントをセンターに
const setFlickity = (index) => {
  const flg_flickity = $(".sw-flickity").length;
  if (isSP && flg_flickity) {
    $(".sw-flickity").eq(index).flickity({
      "cellAlign": "center", //センター揃えはデフォルトなので不要
      "prevNextButtons": false
    });
  }
}

// data-selected属性につけた値と同じdata-showitem属性を付けたコンテンツが表示
const switchDisplay = (selected, showItemName) => {
  selected = (typeof selected === 'undefined') ? 'unselected' : selected; // IEはデフォルト引数の記述が使えないので
  showItemName = (typeof showItemName === 'undefined') ? 'showitem' : showItemName; // IEはデフォルト引数の記述が使えないので
  $(`[data-${showItemName}]`).hide(); // data属性セレクタの書き方に要注意
  if (selected !== 'unselected') {
    const $showItem = $(`[data-${showItemName}="${selected}"]`); // data属性セレクタの書き方に要注意
    $showItem.fadeIn("fast");
    onClickScrollPosition($showItem);
  }
}

// 選択するトリガーの要素にdata-selected属性をつける。
// data-selected属性につけた値と同じdata-showitem属性を付けたコンテンツが表示される
// data-selected属性とdata-showitem属性を対にすること
const selectSwitch = (e) => {
  const $selectbtn = $(e.target);
  const selectDataset = Object.keys(e.target.dataset); //押されたボタンのdatasetのキー名一覧
  const datasetName = selectDataset[0]; //押されたボタンのdataset名を取得
  const $selectbtns = ($selectbtn.siblings().length) ? $selectbtn.siblings() : $('[data-' + selectDataset + ']'); //同じdataset名を持つボタンか兄弟要素
  const selected = e.target.dataset.selected;

  $selectbtns.removeClass("btn_active btn_selectable").addClass("btn_unselect");
  $selectbtn.toggleClass("btn_active");
  switchDisplay(selected);
}

//googleカスタム検索のカスタマイズ
const init_googlesearchbox = () => {
  $('#cse-search-box input[type="text"]').attr('placeholder', 'b-mobileサイト内検索').css({
    'background': '',
    'text-indent': '0'
  });
};

const gen_scrollStopEvent = () => { // http://www.risewill.co.jp/blog/archives/2618
  const scrollStopEvent = new $.Event('scrollstop'); //スクロールストップを検知するjQueryイベントオブジェクトを生成
  let timer;
  const scrollStopEventTrigger = () => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {$(window).trigger(scrollStopEvent)}, 100);
  }
  $(window).on('scroll', scrollStopEventTrigger);
  $('body').on('touchmove', scrollStopEventTrigger);
};

//header固定
const headerFix = () => {
  const $nav = $('header');
  if ($(window).scrollTop() > 100) {
    $nav.addClass('fixed');
  } else {
    $nav.removeClass('fixed');
  }
};

// TOPに戻るボタンの表示・非表示
const disptoTOPButton = () => {
  const $element = $('.toTopButton');
  const scrollHeight = $(document).height();
  const scrollPosition = $(window).height() + $(window).scrollTop();
  const isScrolledBottom = ((scrollHeight - scrollPosition) / scrollHeight <= 0.05) ? true : false;
  if ($(window).scrollTop() > 1000 || isScrolledBottom) { // 現在スクロール位置が1000px以上または下まで到達
    $element.addClass('toTopButton-visible');
  } else {
    $element.removeClass('toTopButton-visible');
  }
};

// ボタンの高さを揃える 高さを揃える親要素にjs-heightAdjustをclass指定する
const heightAdjust = () => {
  $('.js-heightAdjust').each((i, elem) => {
    const $btn = $(elem).find('[class^="btn"]');
    //ボタンの高さはボタンの中の文字数に依存するためボタンの最大文字数を取得
    const maxtext = Math.max(...$btn.map((i, elem) => elem.textContent.length));
    const maxHeight = Math.floor(maxtext / 13) * 30 + 18; // 13文字以上折り返して高くなる
    $btn.height(maxHeight);
  });
};

// 消費税・金額を税別→税込へ
const tax_disp = () => {
  const flg_intax = true; // 税別表記中はfalse
  const taxnote = (flg_intax) ? '特別な表記のない場合、価格は税込です' : '特別な表記のない場合、価格は税別です';
  $('.js-taxnote').text(taxnote);
  const taxrate = 1.10;
  $('.js-price_tax').each((i, elem) => {
    const price = Number(elem.textContent);
    const priceinTax = (flg_intax) ? Math.floor(price * taxrate) : price;
    const price_txt = String(priceinTax).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') + '円';
    elem.textContent = price_txt;
  });
};

const imageSwitch_src = elem => {
  const srcname = elem.src;
  const srcarray = srcname.split('/');
  const filename_ex = srcarray.pop(); // 拡張子付きでファイル名だけ取得
  //const filename_ex = srcname.match(".+/(.+?)([\?#;].*)?$")[1]; // 拡張子付きでファイル名だけ取得
  const imgSrc = filename_ex.split('.');
  const imgPath = srcarray.join('/') + '/'; //末尾にはつかないので
  imgSrc[0] = imgSrc[0].replace(/_pc$|_sp$/g, '');
  let filename = imgSrc[0];
  if (isSP) { //画面幅=スマホ
    filename = imgSrc[0] + '_sp.' + imgSrc[1];
    filename = filename.replace(/_pc_sp/g, '_sp');
  } else {
    filename = imgSrc[0] + '_pc.' + imgSrc[1];
    filename = filename.replace(/_pc_pc/g, '_pc');
  }
  return imgPath + filename;
};

const imageInitload = () => {
  $('.js-image-switch').each((i, elem) => {
    elem.src = imageSwitch_src(elem);
  });
};

const changeHeaderBizlinkname = () => {
  if (isSP) {
    $('.js-headerBizLink').text('法人のお客様');
  } else {
    $('.js-headerBizLink').text('合理的かけほ法人用お申し込みページ');
  }
};

const imageSwitch = () => {
  $('.js-image-switch').each((i, elem) => {
    const img_src = elem.src;
    elem.src = (isSP) ? img_src.replace('_pc', '_sp') : img_src.replace('_sp', '_pc');
  });
};

$(() => {
  $(window).on('resize', () => {
    isSP = return_isSP();
    clearTimeout(resizeTimer);
    const resizeTimer = setTimeout(() => {
      imageInitload(); //レスポンシブ対応画像チェンジ
      //gnaviInitload();
      footer_accordion({type: 'resize'});
    }, 100);
    return false;
  });

  gen_scrollStopEvent();
  //init_oldipad();
  //init_googlesearchbox();
  //globalmenuCurrent(); //グローバルメニューハイライト
  //hilightCurrent(); //menu現在地ハイライト
  imageInitload();
  //gnaviInitload();
  replace_txtArrow();
  currentAutoSlide();
  heightAdjust();
  tax_disp();
  //changeHeaderBizlinkname(); // ヘッダーの法人へのリンクの名前変更

  //アコーディオンメニュー
  //e:ハンドリングイベント
  //elem:アコーディオンスイッチ部分。クリックされた要素
  //init:初期表示状態
  const accordion = ({type}, elem, init = 'open') => {
    const $aco_s = $(elem);
    const $content = $aco_s.next();
    const acomenu_title = ($aco_s.data('title') !== undefined) ? $aco_s.data('title') : "注意事項";

    if (type === "click") {
      const elemtxt = ($aco_s.text().slice(-3).indexOf('見る') < 0) ? '見る' : 'たたむ'; //見るになっていなかったら、「～を見る」にセット
      const toggleContent = new Promise((resolve, reject) => {
        $content.slideToggle('fast', () => {
          resolve(); // jqueryのslideToggleを終わった後にするcallbackにpromiseの終了としてresolveをしておく
        });
      });
      toggleContent
        .then(() => $aco_s.text(`${acomenu_title}を${elemtxt}`)); // コンテンツの表示が切り替わった後に、テキストを変える
    }
  };

  //accordion({type: 'load'}, $('.js-aco_switch'));// デフォルト、初期ロード時はコンテンツを開いた状態に
  [...document.querySelectorAll('.js-aco_switch, .js-aco_switch2')].forEach(elem => {
    elem.addEventListener('click', () => accordion({type: 'click'}, elem), false);
  });

  //フッターのアコーディオン
  const fotterItems = [...document.querySelectorAll('.footer_menu_toggle')];
  const footer_accordion = ({type}, elem) => { // type:イベントタイプ
    if (!isSP) return; // PC何もしない
    if (type === 'load' || type === 'resize') { // ロード時・リサイズ時、フッタは閉じる
      fotterItems.forEach(elem => {
        $(elem).next().hide();
      });
    }
    if (type === 'click') {
      $(elem).find('span').toggleClass('active');
      $(elem).next().slideToggle('fast');
    }
  };

  footer_accordion({type: 'load'}); // スマホの場合、初期ロード時、フッタは閉じておく
  fotterItems.forEach(elem => { // 複数の要素にイベントリスナを付与
    elem.addEventListener('click', () => footer_accordion({type: 'click'}, elem), false);
  });

  const close_btn = document.querySelectorAll('.close_btn');
  [...close_btn].forEach(elem => {
    elem.addEventListener('click', () => { //flow_postご利用開始まで使われている
      const $btn = $(elem);
      $btn.prevAll('#flow_aco dd').slideToggle('fast');
      $btn.toggleClass("active"); //追加部分
      $btn.prev().slideToggle('fast');
    }, false);
  });

  if (elemExist('ul.js-tab_area')) tabSwitch({type: 'load'}); // デフォルト、初期ロード時はコンテンツを開いた状態に
  $('ul.js-tab_area').on('click', 'li', tabSwitch); //第2引数のセレクタ指定でクリックされた要素をtabswitchに渡す

  $(window).on('scrollstop', () => { // スクロールが一時停止したら
    disptoTOPButton(); // スクロール位置によってTOPに戻るボタンを表示・非表示
    headerFix(); // スクロール位置によってナビゲーションメニューを表示・非表示
  });

  // TOPに戻るアニメーション
  $('.toTopButton').on('click', () => {
    $('html').animate({scrollTop: 0}, 'slow');
  });

  //タブ切り替え URLハッシュによって切り替え // URLハッシュで切り替える機能は後日実装 7/26
  let hash = location.hash; //#以降のハッシュ取得
  //hashの中に#tab～が存在するか調べて連想配列に
  hash = (hash.match(/^#tab\d+$/) || [])[0];
  //hashに要素が存在する場合、hashで取得した文字列（#tab2,#tab3等）から#より後を取得(tab2,tab3)
  const index = ($(hash).length) ? hash.slice(1) : 0;
});

export {
  gtmDL,
  isSP,
  isiOS,
  replace_txtArrow,
  putComma,
  currentAutoSlide,
  elemExist,
  URLdiv,
  getUrlDir,
  getHtmlName,
  getUrlParam,
  UrlParamtoArray,
  genSimpleBreadcrumbs,
  onClickScrollPosition,
  tabSwitch,
  setFlickity,
  switchDisplay,
  selectSwitch,
  //init_oldipad,
  //init_googlesearchbox,
  gen_scrollStopEvent,
  headerFix,
  disptoTOPButton,
  heightAdjust,
  tax_disp,
  imageInitload,
  imageSwitch
};

window.gtmDL = gtmDL;
window.isSP = isSP;
window.isiOS = isiOS;
window.elemExist = elemExist;
window.URLdiv = URLdiv;
window.getUrlDir = getUrlDir;
window.getHtmlName = getHtmlName;
window.getUrlParam = getUrlParam;
window.UrlParamtoArray = UrlParamtoArray;
window.genSimpleBreadcrumbs = genSimpleBreadcrumbs;
window.onClickScrollPosition = onClickScrollPosition;
window.tabSwitch = tabSwitch;
window.setFlickity = setFlickity;
window.switchDisplay = switchDisplay;
window.selectSwitch = selectSwitch;
window.headerFix = headerFix;
window.disptoTOPButton = disptoTOPButton;
